import { utils } from '@makeship/core';
import * as Sentry from '@sentry/nextjs';
import { getCollectionByHandle, getCollectionById } from '../api/collections';
import { getProductByHandle } from '../api/product';
import { EvergreenInventoryState, productMOQ, ProductStage, ProductTag } from '../types/common';
import {
  getStage,
  parseMetafields,
  productArrayContainsProductTag,
  getProgressBarProps,
  findCollectionByMetafield,
  getProductIDFromShopifyGID,
} from './product';
import { getProductSalesQuantityByExternalProductId } from '../api/storefront/product';

import config from '../../config.json';

const { pagination, collections } = config;

export const filterProducts = (
  products: Shopify.ProductEdge[],
  showEvergreen = true,
  showLivePetitions = true,
  showPastPetitions = true,
): Shopify.ProductEdge[] => {
  let productsToDisplay = products.filter((product) => {
    const { tags } = product.node;
    const isHidden = tags.includes(ProductTag.Hidden);
    const isDraft = tags.includes(ProductStage.Draft);

    return !isHidden && !isDraft && isCampaignLaunched(product);
  });

  if (!showEvergreen) {
    productsToDisplay = productsToDisplay.filter(
      (product) =>
        !product.node.tags.includes(ProductTag.Evergreen) || product.node.tags.includes(ProductTag.Collective),
    );
  }

  if (!showLivePetitions) {
    productsToDisplay = productsToDisplay.filter((product) => !product.node.tags.includes(ProductStage.Petition));
  }

  if (!showPastPetitions) {
    productsToDisplay = productsToDisplay.filter(
      (product) => !product.node.tags.includes(ProductStage.PetitionSuccess),
    );
  }

  return productsToDisplay;
};

const isCampaignLaunched = (product: Shopify.ProductEdge): boolean =>
  product.node.tags.includes(ProductStage.Live) ||
  product.node.tags.includes(ProductStage.ComingSoon) ||
  product.node.tags.includes(ProductStage.Past) ||
  product.node.tags.includes(ProductStage.Petition) ||
  product.node.tags.includes(ProductStage.PetitionSuccess);

export const getProductsFromCollection = async (handle: string): Promise<Shopify.ProductEdge[]> => {
  let products: Shopify.ProductEdge[] = [];
  let cursor: string | undefined;

  do {
    // eslint-disable-next-line no-await-in-loop
    const newProducts = await getNextPage(handle, cursor);
    cursor = newProducts?.[newProducts.length - 1]?.cursor;

    if (!newProducts || newProducts.length === 0) {
      break;
    }

    const productsToAdd = filterProducts(newProducts || [], isEvergreenCreator(handle));

    products = products.concat(productsToAdd);
  } while (cursor);

  return products;
};

export const getNextPage = async (
  handle: string,
  lastProductCursor: string | undefined,
): Promise<Shopify.ProductEdge[] | undefined> => {
  const newCollection = await getCollectionByHandle(handle, lastProductCursor);

  return newCollection?.products?.edges;
};

export const getCollectionProducts = (
  products: Shopify.ProductEdge[],
  endingSoon?: boolean,
  showEvergreen?: boolean,
  showLivePetitions?: boolean,
  showPastPetitions?: boolean,
): Shopify.ProductEdge[] =>
  getSortedProducts(filterProducts(products, showEvergreen, showLivePetitions, showPastPetitions), endingSoon);

export const getSortedProducts = (products: Shopify.ProductEdge[], endingSoon?: boolean): Shopify.ProductEdge[] =>
  products.sort(({ node: a }, { node: b }) => {
    const aStage = getStage(a.tags);
    const bStage = getStage(b.tags);

    const aMetafields = parseMetafields(a.metafields);
    const bMetafields = parseMetafields(b.metafields);

    const aMoq = Number(aMetafields.moq) || productMOQ[a.productType] || config.defaultMOQ;
    const bMoq = Number(bMetafields.moq) || productMOQ[b.productType] || config.defaultMOQ;

    const aIsLimited = a.tags.includes('limited');
    const bIsLimited = b.tags.includes('limited');

    const aInventory = Math.abs(a.totalInventory || 0);
    const bInventory = Math.abs(b.totalInventory || 0);

    // Sort ending soon collection by remaining time
    if (endingSoon) {
      if ((aMetafields?.enddate || '') < (bMetafields?.enddate || '')) return -1;
      if ((aMetafields?.enddate || '') > (bMetafields?.enddate || '')) return 1;
      return 0;
    }

    if (aStage === bStage) {
      if (aStage === ProductStage.ComingSoon) {
        // Ensure products without launch date is at back of sorted array
        if ((aMetafields?.launch || Number.MAX_VALUE) < (bMetafields?.launch || Number.MAX_VALUE)) return -1;
        if ((aMetafields?.launch || Number.MAX_VALUE) > (bMetafields?.launch || Number.MAX_VALUE)) return 1;
        return 0;
      }
      if (aStage === ProductStage.Past) {
        if (aIsLimited && bIsLimited) {
          return Number(aMoq - aInventory) < Number(bMoq - bInventory) ? 1 : -1;
        }
        if (aIsLimited) {
          return aMoq < Number(bMetafields?.totalsold || Math.abs(Number(b.totalInventory || 0))) ? 1 : -1;
        }
        if (bIsLimited) {
          return bMoq < Number(aMetafields?.totalsold || Math.abs(Number(a.totalInventory || 0))) ? -1 : 1;
        }
        if (
          Number(aMetafields?.totalsold || Math.abs(Number(a.totalInventory || 0))) / aMoq <
          Number(bMetafields?.totalsold || Math.abs(Number(b.totalInventory || 0))) / bMoq
        )
          return 1;
        if (
          Number(aMetafields?.totalsold || Math.abs(Number(a.totalInventory || 0))) / aMoq >
          Number(bMetafields?.totalsold || Math.abs(Number(b.totalInventory || 0))) / bMoq
        )
          return -1;
        return 0;
      }
    } else if (aStage === ProductStage.Live || bStage === ProductStage.Live) {
      // Show live products first
      return aStage === ProductStage.Live ? -1 : 1;
    } else if (aStage === ProductStage.ComingSoon || bStage === ProductStage.ComingSoon) {
      // Show Coming Soon products next
      return aStage === ProductStage.ComingSoon ? -1 : 1;
    } else if (aStage === ProductStage.Failed || bStage === ProductStage.Failed) {
      // Show failed and past campaigns last
      return aStage === ProductStage.Failed ? 1 : -1;
    } else if (aStage === ProductStage.Past || bStage === ProductStage.Past) {
      return aStage === ProductStage.Past ? 1 : -1;
    }

    if (aIsLimited || bIsLimited) {
      if (aIsLimited && bIsLimited) {
        return Number(aMoq - aInventory) < Number(bMoq - bInventory) ? 1 : -1;
      }
      if (aIsLimited) {
        return Number(aMoq - aInventory) < Number(bInventory) ? 1 : -1;
      }
      if (bIsLimited) {
        return Number(aInventory) < Number(bMoq - bInventory) ? 1 : -1;
      }
    }

    return Number(aInventory) / aMoq < Number(bInventory) / bMoq ? 1 : -1;
  });

export const getEndingSoonProducts = async (handle: string): Promise<Shopify.ProductEdge[]> => {
  const liveCampaigns = await getProductsFromCollection(handle);

  const endingSoon = filterProducts(liveCampaigns).filter(({ node }) => {
    const metafields = parseMetafields(node.metafields);
    if (metafields.enddate) {
      const diff = utils.parseDate(metafields.enddate).diff(utils.dateNow(), 'day');
      return diff >= 0 && diff < 7;
    }
    return false;
  });

  return endingSoon;
};

export const getNewArrivalProducts = async (handle: string): Promise<Shopify.ProductEdge[]> => {
  const liveCampaigns = await getProductsFromCollection(handle);

  const newArrivals = filterProducts(liveCampaigns).filter(({ node }) => {
    const metafields = parseMetafields(node.metafields);
    if (metafields.launch) {
      const diff = utils.dateNow().diff(utils.parseDate(metafields.launch), 'day');
      return diff >= 0 && diff < 7;
    }
    return false;
  });

  return newArrivals;
};

export const getSortedSearchResults = (
  productsByTitle: Shopify.ProductEdge[],
  productsByCreator: Shopify.ProductEdge[],
  searchableProducts: Shopify.ProductEdge[],
): Shopify.ProductEdge[] => {
  const isEndingSoonCarousel = false;
  const searchResults = productsByCreator.concat(productsByTitle, searchableProducts);
  const sortedResults = getSortedProducts(filterProducts(searchResults), isEndingSoonCarousel);

  const seen = new Set();

  const uniqueSearchResults = sortedResults.filter((result) => {
    const duplicate = seen.has(result.node.id);
    if (!duplicate) {
      seen.add(result.node.id);
    }
    return !duplicate;
  });

  return uniqueSearchResults;
};

export const isSpecialCollection = (handle: string | undefined): boolean =>
  handle === collections.webtoon ||
  handle === collections.ninjakiwi ||
  handle === collections.kingsisleentertainment ||
  handle === collections.vinyls ||
  handle === collections.petitions ||
  handle === collections.guiltygearstrive ||
  handle === collections.hololive;

export const filterSuccessfulProducts = (productEdges: Shopify.ProductEdge[]): Shopify.ProductEdge[] =>
  [...productEdges].filter((productEdge) => getStage(productEdge.node.tags) !== ProductStage.Failed);

export const filterLiveFromCollection = (products: Shopify.ProductEdge[]): Shopify.ProductEdge[] =>
  [...products].filter((product) => getStage(product.node.tags) === ProductStage.Live);

export const filterLiveAndComingSoonFromCollection = (products: Shopify.ProductEdge[]): Shopify.ProductEdge[] =>
  [...products].filter(
    (product) =>
      getStage(product.node.tags) === ProductStage.Live || getStage(product.node.tags) === ProductStage.ComingSoon,
  );

export const getIsCompleteTheCollectionEligible = (
  sameCreatorProducts: Shopify.ProductEdge[],
  makeshiftCollectionProducts: Shopify.ProductEdge[],
  hasProductionDesign: boolean,
  stage: ProductStage,
  isSoldOut: boolean,
  product: Shopify.Product,
  pinAddOnProduct?: Shopify.Product | undefined | null,
): boolean => {
  const hasEligibleSameCreatorProducts =
    sameCreatorProducts.length > 0 && !productArrayContainsProductTag(sameCreatorProducts, ProductStage.Petition);

  const hasEligibleMakeshiftCollectionProducts =
    makeshiftCollectionProducts.length > 0 &&
    !productArrayContainsProductTag(makeshiftCollectionProducts, ProductStage.Petition);

  const hasEligibleProducts =
    pinAddOnProduct || hasEligibleSameCreatorProducts || hasEligibleMakeshiftCollectionProducts;

  const isProductAvailable = (stage === ProductStage.Live && product.availableForSale) || stage === ProductStage.Draft;

  const isEligible = hasEligibleProducts && !hasProductionDesign && isProductAvailable && !isSoldOut;

  return isEligible;
};

export const isEvergreenCreator = (handle: string): boolean => handle === 'the-click';

export const shouldDisplayBadge = (
  stage: ProductStage,
  sale: boolean,
  inventoryState: EvergreenInventoryState | undefined,
) => {
  const stagesWithBadge = [ProductStage.ComingSoon, ProductStage.Past, ProductStage.PetitionSuccess];

  return (
    stagesWithBadge.includes(stage) ||
    sale ||
    inventoryState === EvergreenInventoryState.LowStock ||
    inventoryState === EvergreenInventoryState.SoldOut
  );
};

export const filterCarouselProducts = (products: Shopify.ProductEdge[], productID: string): Shopify.ProductEdge[] =>
  products
    .filter((product) => {
      const isHidden = product.node.tags.includes(ProductTag.Hidden);
      const isDraft = product.node.tags.includes(ProductStage.Draft);

      return product.node.tags.includes(ProductStage.PetitionSuccess)
        ? isCampaignLaunched(product) && !isHidden && !isDraft
        : !isHidden &&
            !isDraft &&
            !product.node.tags.includes(ProductStage.Petition) &&
            !product.node.tags.includes(ProductStage.PetitionFailed);
    })
    .filter((product) => product.node.id !== productID);

export const isCreatorMessageEligible = (
  isCreatorBranded: boolean | undefined,
  creatorMessage: string | undefined,
  hasLiveProducts: boolean | undefined,
  isPreview: boolean | undefined,
  previewCreatorMessage: string | undefined,
) => (!!isPreview && !!previewCreatorMessage) || (!!isCreatorBranded && !!creatorMessage && !!hasLiveProducts);

export const parseCreatorCollectionMetafields = (
  metafieldsRaw: Shopify.Metafield[] | undefined,
): CreatorCollectionMetafields =>
  metafieldsRaw
    ? metafieldsRaw.reduce((acc, metafield) => {
        if (!metafield) return acc;
        if (
          metafield.key === 'isCreatorBranded' ||
          metafield.key === 'isProductDropCollection' ||
          metafield.key === 'shouldEnableStretchGoals'
        ) {
          return { ...acc, [metafield.key]: stringToBoolean(metafield.value) };
        }
        if (
          metafield.key === 'creatorAvatar' ||
          metafield.key === 'creatorLogo' ||
          metafield.key === 'headerBackground' ||
          metafield.key === 'previewCreatorLogo' ||
          metafield.key === 'previewCreatorAvatar' ||
          metafield.key === 'previewHeaderBackground'
        ) {
          return { ...acc, [`${metafield.key}URL`]: metafield.reference?.image?.url };
        }

        if (metafield.key === 'productDropCollections') {
          try {
            const parsedCollections = metafield.references?.edges.map(
              (edge: { node: any }) => edge.node as Shopify.Collection,
            );
            return { ...acc, productDropCollections: parsedCollections };
          } catch (error) {
            Sentry.captureException(error);
            return acc;
          }
        }

        return { ...acc, [metafield.key]: metafield.value };
      }, {} as CreatorCollectionMetafields)
    : {};

export const parseProductDropCollectionMetafields = (
  metafieldsRaw: Shopify.Metafield[] | undefined,
): ProductDropCollectionMetafields =>
  metafieldsRaw
    ? metafieldsRaw.reduce((acc, metafield) => {
        if (!metafield) return acc;

        if (metafield.key === 'shouldEnableStretchGoals' || metafield.key === 'isProductDropCollection') {
          acc[metafield.key] = stringToBoolean(metafield.value);
          return acc;
        }

        if (metafield.key === 'stretchGoalsData') {
          try {
            acc.stretchGoalsData = JSON.parse(metafield.value);
          } catch (error) {
            Sentry.captureException(error);
          }
          return acc;
        }

        if (metafield.key === 'stretchGoalsImages') {
          try {
            acc.stretchGoalsImagesURLs = metafield.references.edges.map((edge) => edge.node.image.url);
          } catch (error) {
            Sentry.captureException(error);
          }
          return acc;
        }

        return { ...acc, [metafield.key]: metafield.value };
      }, {} as ProductDropCollectionMetafields)
    : {};

export const stringToBoolean = (value: string | undefined): boolean => value?.toLowerCase() === 'true';

export const areAllProductDropMetafieldsFilled = (
  metafields: ProductDropCollectionMetafields,
): metafields is Required<ProductDropCollectionMetafields> =>
  metafields.shouldEnableStretchGoals !== undefined &&
  metafields.isProductDropCollection !== undefined &&
  metafields.stretchGoalsData !== undefined &&
  metafields.stretchGoalsImagesURLs !== undefined &&
  metafields.stretchGoalsTheme !== undefined;

// This function is used to get the latest product drop collection from a list of collections. We determine the latest product drop collection by comparing the drop number in the collection title.
export const getLatestProductDropCollection = (collections: Shopify.Collection[]): Shopify.Collection | null => {
  if (collections.length === 0) return null;

  return collections.reduce((latestProductDropCollection, currentCollection) => {
    const maxDropNumber = extractDropNumber(latestProductDropCollection.title);
    const currentDropNumber = extractDropNumber(currentCollection.title);

    return currentDropNumber > maxDropNumber ? currentCollection : latestProductDropCollection;
  });
};

// TODO: We might need to update this logic
function extractDropNumber(title: string): number {
  const match = title.match(/Drop\s*(\d+)/i);
  return match ? parseInt(match[1], 10) : 0;
}

export const evaluateStretchGoals = async (
  productDropCollection: Shopify.Collection,
  metafields: ProductDropCollectionMetafields,
): Promise<{ canDisplayStretchGoals: boolean; goalsAchieved: boolean; productDropSalesQuantity: number }> => {
  if (!areAllProductDropMetafieldsFilled(metafields)) {
    return { canDisplayStretchGoals: false, goalsAchieved: false, productDropSalesQuantity: 0 };
  }

  const { shouldEnableStretchGoals, isProductDropCollection } = metafields;
  if (!shouldEnableStretchGoals || !isProductDropCollection) {
    return { canDisplayStretchGoals: false, goalsAchieved: false, productDropSalesQuantity: 0 };
  }

  try {
    if (!productDropCollection.products || productDropCollection.products.edges.length === 0) {
      return { canDisplayStretchGoals: false, goalsAchieved: false, productDropSalesQuantity: 0 };
    }

    // Ensure all products in the collection are live
    if (
      productDropCollection.products.edges.some((productEdge) => getStage(productEdge.node.tags) !== ProductStage.Live)
    ) {
      return { canDisplayStretchGoals: false, goalsAchieved: false, productDropSalesQuantity: 0 };
    }

    // Fetch sales quantity and funding status for each product
    const results = await Promise.all(
      productDropCollection.products.edges.map(async (productEdge: Shopify.ProductEdge) => {
        const product = productEdge.node;
        const productSalesQuantity = await getProductSalesQuantityByExternalProductId(
          getProductIDFromShopifyGID(product.id),
        )
          .then((response) => response.data)
          .catch(() => null);

        if (!productSalesQuantity) {
          return { funded: false, quantity: 0 };
        }

        const { funded } = getProgressBarProps(product, false, productSalesQuantity.quantity);
        return { funded, quantity: productSalesQuantity.quantity };
      }),
    );

    // Check if every product has met its MOQ (i.e. is funded)
    const allFunded = results.every((result) => result.funded === true);

    // Calculate the total sales quantity for the entire product drop
    const productDropSalesQuantity = results.reduce((sum, result) => sum + result.quantity, 0);

    // Check if stretch goals have been achieved
    const goalsAchieved = areStretchGoalsAchieved(metafields, productDropSalesQuantity);

    return {
      canDisplayStretchGoals: allFunded,
      goalsAchieved,
      productDropSalesQuantity,
    };
  } catch (error) {
    Sentry.captureException(error);
    return { canDisplayStretchGoals: false, goalsAchieved: false, productDropSalesQuantity: 0 };
  }
};

export const fetchLatestProductDropDetails = async (
  productDropCollections: Shopify.Collection[],
  numberOfCollectionProducts: number,
  productId?: string,
) => {
  const latestPartialDropCollection = getLatestProductDropCollection(productDropCollections);
  const latestPartialDropCollectionId = latestPartialDropCollection?.id;

  if (!latestPartialDropCollection || !latestPartialDropCollectionId) {
    return {
      productDropCollectionMetafields: null,
      latestProductDropCollection: null,
      shouldEnableStretchGoals: false,
      goalsAchieved: false,
      productDropSalesQuantity: 0,
    };
  }
  const latestProductDropCollection = await getCollectionById(
    latestPartialDropCollectionId,
    undefined,
    1,
    numberOfCollectionProducts,
  );

  if (!latestProductDropCollection) {
    return {
      productDropCollectionMetafields: null,
      latestProductDropCollection: null,
      shouldEnableStretchGoals: false,
      goalsAchieved: false,
      productDropSalesQuantity: 0,
    };
  }

  if (productId) {
    const isProductPartOfTheProductDropCollection = latestProductDropCollection.products.edges.some(
      (productEdge) => productEdge.node.id === productId,
    );

    if (!isProductPartOfTheProductDropCollection) {
      return {
        productDropCollectionMetafields: null,
        latestProductDropCollection: null,
        shouldEnableStretchGoals: false,
        goalsAchieved: false,
        productDropSalesQuantity: 0,
      };
    }
  }

  const productDropCollectionMetafields = parseProductDropCollectionMetafields(latestProductDropCollection.metafields);

  const { canDisplayStretchGoals, goalsAchieved, productDropSalesQuantity } = await evaluateStretchGoals(
    latestProductDropCollection,
    productDropCollectionMetafields,
  );

  return {
    productDropCollectionMetafields,
    latestProductDropCollection,
    shouldEnableStretchGoals: canDisplayStretchGoals,
    goalsAchieved,
    productDropSalesQuantity,
  };
};

export const areStretchGoalsAchieved = (
  productDropCollectionMetafields: Required<ProductDropCollectionMetafields>,
  productDropSalesQuantity: number,
): boolean => {
  const { stretchGoalsData } = productDropCollectionMetafields;
  const { milestones } = stretchGoalsData;
  return productDropSalesQuantity >= milestones[milestones.length - 1];
};

export const fetchCYCCollectionTitleForStretchGoalUpdates = (
  shouldEnableStretchGoals: boolean,
  productDropCollectionMetafields: ProductDropCollectionMetafields | null,
  productDropSalesQuantity: number | null,
  creatorName: string,
  pinAddOnProduct: Shopify.Product | undefined | null,
) => {
  if (
    !shouldEnableStretchGoals ||
    !productDropCollectionMetafields ||
    !areAllProductDropMetafieldsFilled(productDropCollectionMetafields) ||
    productDropSalesQuantity === null
  ) {
    return;
  }

  const goalsAchieved = areStretchGoalsAchieved(productDropCollectionMetafields, productDropSalesQuantity);

  return goalsAchieved || !!pinAddOnProduct
    ? undefined
    : `Complete your ${creatorName} Collection to help unlock a free gift!`;
};

export const fetchCreatorCollectionDetails = async (product: Shopify.Product) => {
  const detailedProduct = await getProductByHandle(product.handle);
  if (!detailedProduct) {
    return {
      productDropCollectionMetafields: null,
      latestProductDropCollection: null,
      shouldEnableStretchGoals: false,
      goalsAchieved: false,
    };
  }

  const creatorCollection = findCollectionByMetafield(detailedProduct, 'isCreatorCollection', 'true');

  const detailedCreatorCollection = creatorCollection
    ? await getCollectionByHandle(creatorCollection.node.handle, undefined, 1, pagination.completeCollection).then(
        (collection) => collection,
      )
    : null;

  let productDropCollectionMetafields: ProductDropCollectionMetafields | null = null;
  let latestProductDropCollection: Shopify.Collection | null = null;
  let shouldEnableStretchGoals = false;
  let goalsAchieved = false;

  if (detailedCreatorCollection) {
    const { metafields } = detailedCreatorCollection;

    const creatorCollectionMetafields = parseCreatorCollectionMetafields(metafields);

    const { productDropCollections } = creatorCollectionMetafields;

    if (productDropCollections) {
      ({
        productDropCollectionMetafields,
        latestProductDropCollection,
        shouldEnableStretchGoals,
        goalsAchieved,
      } = await fetchLatestProductDropDetails(productDropCollections, pagination.completeCollection));
    }
  }

  return { productDropCollectionMetafields, latestProductDropCollection, shouldEnableStretchGoals, goalsAchieved };
};

export async function fetchFullCollection(
  collectionHandle: string,
  imageCount = 1,
): Promise<Shopify.Collection | null> {
  const completeCollection = 250;

  const fullCollection = await getCollectionByHandle(collectionHandle, undefined, imageCount, completeCollection);

  if (!fullCollection) {
    return null;
  }

  // eslint-disable-next-line prefer-const
  let { edges, pageInfo } = fullCollection.products;
  let cursor: string | undefined = pageInfo.endCursor ?? undefined;

  while (pageInfo.hasNextPage) {
    // eslint-disable-next-line no-await-in-loop
    const nextPageCollection = await getCollectionByHandle(collectionHandle, cursor, imageCount, completeCollection);

    if (!nextPageCollection) {
      break;
    }

    const newEdges = nextPageCollection.products.edges;
    edges = edges.concat(newEdges);

    const { endCursor } = nextPageCollection.products.pageInfo;

    if (!endCursor) {
      break;
    }

    cursor = endCursor;
  }

  fullCollection.products = {
    ...fullCollection.products,
    edges,
  };

  return fullCollection;
}
